import React from 'react';
import {EntriesWrapper} from '../components/entryFiles/EntriesWrapper'

function Library() {
    return (
        <div>
            <EntriesWrapper />
        </div>
    );
}

export default Library;